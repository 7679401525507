
interface PDFProps {
  src: string,
  altText?: string,
  width?: string,
  height?: string
}

export const PDF = ({src, altText, width = '100%', height = '1200px'}: PDFProps) => {
  return (
    <object 
      width={width}
      height={height}
      data={src} 
      type="application/pdf"
    >
      {altText}
    </object>
  )
}
