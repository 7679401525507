export enum Social {
  Facebook = 'https://www.facebook.com/edithguzmanrealtor',
  Instagram = 'https://www.instagram.com/EdithGuzmanRealtor',
  Youtube = 'https://www.youtube.com/@EdithGuzmanRealtor',
  Linkedin = 'https://www.linkedin.com/in/edithguzmanrealtor/',
  Snapchat = 'https://twitter.com/edithgrealtor',
  GoogleMapsBusiness = 'https://maps.app.goo.gl/JM1ngmDxuEjwAqRLA',
  Yelp = 'https://yelp.to/DHQkmzSark',
  Zillow = 'https://www.zillow.com/profile/edithguzmanrealtor',
  Realtor = 'https://www.realtor.com/realestateagents/65cd441f0abc487989a09605',
}
