import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import GlobalStyles from './styles/GlobalStyles'
import { HelmetProvider } from 'react-helmet-async'
import { useInitiateLanguage } from './helpers/languageHooks'
import Paths from './constants/paths'
import HomePage from './views/home/HomePage'
import { TestPage } from './views/TestPage'
import { useInitializeGoogleAnalytics } from '@/utils/analytics.ts'
import { AboutPage } from './views/AboutPage'
import { HomesPage } from './views/HomesPage'
import { Home10208RedCurrant } from './views/Page10208RedCurrant'
import { BlogPage } from './views/BlogPage'
import { BlogCubanSandwichFestival2024 } from './views/blog/BlogCubanSandwichFestival2024'

export default function App() {
  console.log(`App running in ${process.env.REACT_APP_NODEENV} mode...`)

  useInitializeGoogleAnalytics()
  useInitiateLanguage()

  return (
    <>
      <GlobalStyles />
      <HelmetProvider>
        <Router>
          <Routes>
            <Route path={Paths.Home.loc} element={<HomePage />} />
            <Route path={Paths.Test.loc} element={<TestPage />} />
            <Route path={Paths.About.loc} element={<AboutPage />} />
            <Route path={Paths.Homes.loc} element={<HomesPage />} />
            <Route
              path={Paths.Home10208RedCurrant.loc}
              element={<Home10208RedCurrant />}
            />
            <Route path={Paths.Blog.loc} element={<BlogPage />} />
            <Route path={Paths.BlogCubanSandwichFestival2024.loc} element={<BlogCubanSandwichFestival2024 />} />
          </Routes>
        </Router>
      </HelmetProvider>
    </>
  )
}
