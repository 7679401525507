// changeFreq options: always | hourly | daily | weekly | monthly | yearly | never
const Paths = {
  About: { loc: '/about', changefreq: 'monthly', priority: 0.5 },
  Home: { loc: '/', changefreq: 'daily', priority: 0.7 },
  Test: { loc: '/test', changefreq: 'never', priority: 0.1 },
  Homes: { loc: '/homes', changefreq: 'daily', priority: 0.7 },
  Home10208RedCurrant: {
    loc: '/homes/10208RedCurrant',
    changefreq: 'weekly',
    priority: 0.4,
  },
  Blog: {loc: '/blog', changefreq: 'daily', priority: 0.7},
  BlogCubanSandwichFestival2024: {loc: '/blog/cuban-sandwich-festival-tampa-2024', changefreq: 'monthly', priority: 0.2},
}

Object.freeze(Paths)

module.exports = Paths
