import { HomeData } from '@/types/homes'
import { PDF } from '../misc/PDF'
import { homeAddressStr } from '@/helpers/homes'
homeAddressStr

interface PDFProps {
  data: HomeData
  src: string
  altText?: string
  width?: string
  height?: string
}
export const PDFHome = (props: PDFProps) => (
  <PDF
    {...props}
    altText={
      'Information about the home located at ' + homeAddressStr(props.data)
    }
  />
)
