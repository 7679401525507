import tw from 'twin.macro'
import styled from 'styled-components'
import { ReactComponent as FacebookIcon } from '@/images/icons/facebook-icon.svg'
import { ReactComponent as InstagramIcon } from '@/images/icons/instagram-icon.svg'
import { ReactComponent as LinkedIn } from '@/images/icons/linkedin-icon.svg'
import { ReactComponent as YoutubeIcon } from '@/images/icons/youtube-icon.svg'
import { Social } from '@/constants/social'

const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block text-gray-100 hover:text-gray-500 transition duration-300 mx-4`}
  svg {
    ${tw`w-5 h-5`}
  }
`

export const SocialLinks = () => {
  return (
    <div className="social_links">
      <SocialLink href={Social.Instagram}>
        <InstagramIcon />
      </SocialLink>
      <SocialLink href={Social.Youtube}>
        <YoutubeIcon />
      </SocialLink>
      <SocialLink href={Social.Facebook}>
        <FacebookIcon />
      </SocialLink>
      <SocialLink href={Social.Linkedin}>
        <LinkedIn />
      </SocialLink>
    </div>
  )
}
