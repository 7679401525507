import { Page } from '@/components/page/Page'
import HomePdf from '@/homes/10208-redCurrant/Home10208RedCurrant.pdf'
import { data, seo } from '@/homes/10208-redCurrant/info'
import { YoutubeEmbed } from '@/components/misc/YoutubeEmbed'
import { VerticalMargin } from '@/styles/page'
import { HomeDetailsTable } from '@/components/homes/HomeDetailsTable'
import { homeAddressStr } from '@/helpers/homes'
import GoogTranslateButton from '@/components/language/GoogTranslationBtn'
import SEO from '@/utils/SEO'
import { PDFHome } from '@/components/homes/PDFHome'

export const Home10208RedCurrant = () => {
  return (
    <Page>
      <SEO {...seo} />
      <GoogTranslateButton />
      <h1>{homeAddressStr(data)}</h1>
      <VerticalMargin>
        <YoutubeEmbed embedId="Ans8uWVmMCA?si=ZWzhQZ0krW5DuRov" />
      </VerticalMargin>

      <HomeDetailsTable data={data} />
      <hr />
      <br />
      <br />
      <p>
        Welcome to the beautiful Ventura Bay Townhome at 10208 Red Currant Ct,
        Riverview, Florida. This charming townhouse, built in 2009, offers 1,622
        square feet of comfortable living space.
      </p>
      <p>
        Listed at $299,500, this home features 3 bedrooms, 2.5 bathrooms, and a
        1-car garage. As you step inside, you'll be greeted by an open concept
        floor palnd and lots of natural lighting, providing a warm and inviting
        atmosphere.
      </p>
      <p>
        The super spacious kitchen boasts granite countertops and stainless
        steel appliances, with ample space for your dining area. Through the
        3-leaf sliding doors, you’ll have access to the beautiful screened and
        covered patio, perfect for enjoying family time and preparing your best
        BBQ.
      </p>
      <p>
        The uniquely designed wooden stairs lead you to the second floor where
        you’ll find all 3 bedrooms. Ventura Bay offers a community pool, with
        the HOA covering landscaping, roof maintenance, sewer, and water. This
        home is 20 miles from Tampa International Airport, 20 miles from Macdill
        Airforce Base, 10 miles to downtown Tampa and ½ mile from Target.
      </p>
      <p>
        Don't miss the chance to own this fantastic townhouse. For more
        information, contact me Edith Austin, or my colleague Matt Mayers at
        Real Broker LLC. Thank you for joining us on this tour. We look forward
        to welcoming you to your new home at Ventura Bay.
      </p>
      <br />
      <h3>Call me today for more information (805) 765-1625</h3>
      <PDFHome
        src={HomePdf}
        data={data}
      />
    </Page>
  )
}
